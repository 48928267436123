import { handleActions } from 'redux-actions';

import { bannersRequestSucceeded } from './actions';
import { ContentfulState } from './types';

const defaultState = {
  banners: [],
  homepage: {
    id: '',
    name: '',
    hero_title: '',
    learner_stories: { id: '', heading: '' },
    goals: [],
  },
};

export const contentful = handleActions<ContentfulState>(
  {
    [`${bannersRequestSucceeded}`]: (
      state,
      { payload }: Required<ReturnType<typeof bannersRequestSucceeded>>
    ) => ({
      ...state,
      banners: payload.banners,
    }),
  },
  defaultState
);
